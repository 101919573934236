import React, { useState } from "react";

import { Col, Row, Typography, Modal, Form, Input, message, Button } from "antd";
import { useFirebase } from "react-redux-firebase";
import { isEmpty } from "lodash";
import styled from "styled-components";

const ProfileNotActivated = ({ profile }) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const firebase = useFirebase();

  const updateUserProfile = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        firebase.updateProfile(values).then(() => {
          setLoading(false);
          message.success(`Vos informations ont été enregistrées`);
        });
      })
      .catch((err) => {
        console.log("Error while trying to update profile in ProfileNotActived: ", err);
        setLoading(false);
        if (isEmpty(err.errorFields)) message.error("Un problème est survenu");
        else message.error("Des informations sont incomplètes");
      });
  };
  return (
    <Modal
      mask={false}
      title="Bienvenue chez e-Nurse"
      visible={true}
      closable={false}
      footer={null}
      maskClosable={false}
      centered={true}
    >
      <Row gutter={50} justify="center">
        <Col>
          <Typography.Text strong>Nous sommes ravis de vous voir !</Typography.Text>
        </Col>
        <Col>
          <Typography.Text>Il semblerait que votre compte ne soit pas encore activé.</Typography.Text>
        </Col>
        <Col>
          <Typography.Text>Contacte-nous par email</Typography.Text>
          {/* <Typography.Paragraph copyable>contact@e-nurse.ch</Typography.Paragraph> */}
        </Col>
      </Row>

      {profile.isEmpty && (
        <Row gutter={50}>
          <Col style={{ marginBottom: 20 }}>
            <Typography.Text>En attendant, vous pouvez déjà remplir quelques informations:</Typography.Text>
          </Col>

          <StyledForm form={form} initialValues={profile}>
            <Row style={{ width: "100%" }} justify="center">
              <Col span={22}>
                <Form.Item
                  name="firstName"
                  label="Prénom"
                  rules={[
                    {
                      required: true,
                      message: "Cette information est nécessaire",
                    },
                  ]}
                >
                  <Input placeholder="Votre prénom" />
                </Form.Item>
              </Col>

              <Col span={22}>
                <Form.Item
                  name="lastName"
                  label="Nom de famille"
                  rules={[
                    {
                      required: true,
                      message: "Cette information est nécessaire",
                    },
                  ]}
                >
                  <Input placeholder="Votre nom de famille" />
                </Form.Item>
              </Col>

              <Col span={22}>
                <Form.Item
                  name="phoneNumber"
                  label="Numéro de téléphone"
                  rules={[
                    {
                      required: true,
                      message: "Cette information est nécessaire",
                    },
                  ]}
                >
                  <Input placeholder="Numéro de téléphone" />
                </Form.Item>
              </Col>
              <Button type="primary" loading={isLoading} onClick={updateUserProfile}>
                Enregistrer
              </Button>
            </Row>
          </StyledForm>
        </Row>
      )}
    </Modal>
  );
};

export default ProfileNotActivated;

export const StyledForm = styled(Form).attrs({
  className: "Styled_Form",
})`
  .ant-form-item-label {
    width: 33%;
    display: flex;
  }

  .ant-form-item-control {
    width: 67%;
  }

  .datePicker {
    width: 100%;
  }

  .CommentInput_Onboarding {
    width: 100%;
  }
`;
