import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Spin } from "antd";

import PrivateRoute from "./routes/utils/PrivateRoute/PrivateRoute";
import { LoadingPage } from "./routes/utils/PrivateRoute/PrivateRoute.styles";

const LoadingComponent = (
  <LoadingPage>
    <Spin size="large" tip="Chargement..." />
  </LoadingPage>
);

const ENurseRouter = lazy(() => import("./ENurseRouter"));
const Landing = lazy(() => import("./routes/Landing/Landing"));
const SignIn = lazy(() => import("./routes/SignIn/SignIn"));
const Terms = lazy(() => import("./routes/PagesSpeciales/Terms"));
const Admin = lazy(() => import("./routes/Admin/Admin"));

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={LoadingComponent}>
        <Route exact path="/" component={Landing} />
        {/* <Route exact path="/connexion" component={SignIn} /> */}
        {/* <Route exact path="/terms-of-service" component={Terms} /> */}
        {/* <Route exact path="/admin" component={Admin} /> */}
      </Suspense>

      {/* <Switch>
        <PrivateRoute path="/protected">
          <Suspense fallback={LoadingComponent}>
            <ENurseRouter />
          </Suspense>
        </PrivateRoute>
      </Switch> */}
    </BrowserRouter>
  );
};

export default App;
