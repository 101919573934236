import React from "react";
import "core-js/stable";

import reportWebVitals from "./reportWebVitals";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import firebase from "firebase/app";

import { createStore } from "redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import "./index.css";
import App from "./App";
import "./config";
import { rootReducer } from "./store/reducers/rootReducer";

// ------ LANGUAGE SETUP ------
// Using ConfigProvider from antd
import { ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/fr-ch";
import frFR from "antd/es/locale/fr_FR";
moment.locale("fr-ch");
// ----------------------------

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  attachAuthIsReady: true,
};

// redux-devtools-extension
const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

// Create store with reducers and initial state
const initialState = {};
const store = createStore(rootReducer, initialState, composeEnhancers());

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ConfigProvider locale={frFR}>
        <App />
      </ConfigProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
