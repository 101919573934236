import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCo0agSnalL8cTtU2Nr7vFaigsFFMj96MU",
  authDomain: "health-innovation-valerie.firebaseapp.com",
  databaseURL: "https://health-innovation-valerie.firebaseio.com",
  projectId: "health-innovation-valerie",
  storageBucket: "health-innovation-valerie.appspot.com",
  messagingSenderId: "791546116782",
  appId: "1:791546116782:web:3059395b92ec442f3a0b3f",
  measurementId: "G-YY1FCY1ZW8",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.auth().languageCode = "fr";

// firebase.firestore.setLogLevel("debug");

export default firebase;
